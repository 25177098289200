import React from "react"
import styled from "styled-components"
import { makeStyles } from "@mui/styles"
import { vat } from "../../../helpers/constants"
import roundNumber from "../../../helpers/roundNumber"
import Image from "../../atoms/image/Image"
import ExciseDutyIcon from "../../../images/icon-excise-duty.png"
import VATIcon from "../../../images/icon-VAT.png"
import EnergyIcon from "../../../images/icon-energy.png"
import EnergyInactiveIcon from "../../../images/icon-energy-inactive.png"
import EnergyNeutralIcon from "../../../images/icon-energy-neutral.png"
import PeopleIcon from "../../../images/icon-people.png"
import ElectricTower from "../../../images/icon-electric-tower.png"

const useStyles = makeStyles(
  theme => ({
    typeBox: {
      '@media (max-width: 767px)': {
        alignItems: 'end',
      }
    },
  }),
  { index: 1 }
)

const TypeBoxContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  font-weight: 500;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1rem;
    height: 2px;
    width: calc(100% - 2rem);
    background-color: #e0e0e0;
  }
`
const TypeBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1rem;
    height: 1px;
    width: calc(100% - 2rem);
    background-color: #e0e0e0;
  }

  &:first-child:after {
    content: none;
  }
`
const TypeBoxTitle = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  word-break: break-word;

  svg {
    height: 24px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    color: red;
  }
`

const TypeBoxPrice = styled.div`
  white-space: nowrap;
`

const TypeBoxImage = styled(Image)`
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin: 0 8px 0 0;
`

const MonthlyBill = ({ priceComposite, totalPrice, greenEnergy, isPibex }) => {
  var energyFontColor = null
  var energyRowBckColor = "#FFF2D9"
  var energyIcon = EnergyNeutralIcon
  var typeBoxPriceContents = null
  if (greenEnergy) {
    energyFontColor = "white"
    energyRowBckColor = "#89BA2A"
    energyIcon = EnergyIcon
    typeBoxPriceContents = (
      <>
        <span className="text-uppercase" style={{ opacity: 0.6, fontSize: "14px" }}>
          Зелена енергия
        </span>{" "}
      </>
    )
  } else if (isPibex) {
    energyFontColor = "#E6401E"
    energyRowBckColor = "#FCE8E4"
    energyIcon = EnergyInactiveIcon
  }

  return (
    <TypeBoxContainer>
      <TypeBox className={useStyles().typeBox}
        style={{
          backgroundColor: energyRowBckColor,
          color: energyFontColor  
        }}
      >
        <TypeBoxTitle className={useStyles().typeBox}>
          <div>
            <TypeBoxImage src={energyIcon} />
          </div>
          <div style={{ color: energyFontColor }}>Енергия</div>
        </TypeBoxTitle>

        <TypeBoxPrice>
          {typeBoxPriceContents}
          <div className="inline-desktop" style={{ textAlign: "end", paddingLeft: "1rem" }}>{roundNumber(priceComposite.price)} лв.</div>
        </TypeBoxPrice>
      </TypeBox>

      <TypeBox>
        <TypeBoxTitle>
          <div>
            <TypeBoxImage src={PeopleIcon} />
          </div>
          <div>Задължения към обществото</div>
        </TypeBoxTitle>

        <TypeBoxPrice> {roundNumber(priceComposite.ZO)} лв.</TypeBoxPrice>
      </TypeBox>

      <TypeBox>
        <TypeBoxTitle>
          <div>
            <TypeBoxImage src={ExciseDutyIcon} />
          </div>
          <div>Акциз</div>
        </TypeBoxTitle>

        <TypeBoxPrice> {roundNumber(priceComposite.duty)} лв.</TypeBoxPrice>
      </TypeBox>

      <TypeBox>
        <TypeBoxTitle>
          <div>
            <TypeBoxImage src={ElectricTower} />
          </div>
          <div>Мрежа</div>
        </TypeBoxTitle>

        <TypeBoxPrice> {roundNumber(priceComposite.network)} лв.</TypeBoxPrice>
      </TypeBox>

      <TypeBox>
        <TypeBoxTitle>
          <div>
            <TypeBoxImage src={VATIcon} />
          </div>
          <div>ДДС</div>
        </TypeBoxTitle>

        <TypeBoxPrice>{roundNumber(totalPrice * vat)} лв.</TypeBoxPrice>
      </TypeBox>
    </TypeBoxContainer>
  )
}

export default MonthlyBill

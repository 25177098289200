import { styled, dialogClasses } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Modal from '../../atoms/modal/Modal';

const staticConditions = [
  { paragraph: 'Договорът е комбиниран;' },
  { paragraph: 'Цена – Борса +;'},
  { paragraph: 'Цената може да се промени с 15- дневно писмено предизвестие от Синергон до Купувача за прекратяване на договора поради промяна на нормативната рамка и обективните обстоятелства при непостигане на съгласие за съгласуване на промените.'},
  { paragraph: 'Срокове за плащане - 5 работни дни, фактуриране на 14-то число на месеца следващ месеца на доставка;'},
  { paragraph: 'Срок – 12 месеца с автоматично удължаване;'},
  { paragraph: 'Електронна фактура;'},
  { paragraph: 'Прекратяване с 30-днево предизвестие възможно от двете страни;'},
  { paragraph: 'Прекратяване само от Синергон – при забава на плащането с повече от 30 дни, при предложение за промяна в цената, което не е прието с 15- дневно писмено предизвестие ( т.3 по-горе);'},
  { paragraph: 'Неустойки – няма;'},
  { paragraph: 'Тежести - прекратяване на договора, Купувачът се задължава да предостави на депозит за обезпечаване на текущи задължения, чийто падеж не е настъпил към момента на подаване на предизвестието. Депозитът е в размер на 150% от последната издадена фактура към момента на подаване на предизвестието.'},
  { paragraph: 'Бонуси - Доставчикът може да заплати задължения на Купувача към предходния му доставчик, като Купувачът трябва да възстанови платените суми, при забава дължи лихва.'},
];

const StyledOl = styled('ol')`
  list-style: none;
  counter-reset: my-awesome-counter;

  & .numbered-list-item {
    counter-increment: my-awesome-counter;
  }

  & .numbered-list-item::before {
    content: counter(my-awesome-counter) ". ";
    color: black;
    font-weight: 600;
    font-size: 24px;
  }

  & .dashed-list {
    list-style-type: '- ';
  }
`;

const StyledRightModal = styled(Modal)(({ theme }) => `
  & .${dialogClasses.paper} {
    max-height: 100%;
    position: absolute;
    margin: 0;
    right: 0;
    height: 100%;
    min-width: 30rem;
  }

  & span {
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
    text-decoration-color: ${theme.palette.secondary.main};
    text-decoration-skip-ink: none;
  }

  & span:hover {
    cursor: pointer;
  }
`);

const OfferConditionsModal = ({ open, handleClose, title, conditions, fileUrl }) => {
  const openLink = () => {
    window.open(fileUrl, '_blank');
  }

  const renderContent = () => {
    let conditionsArr = conditions;

    if (!conditionsArr) {
      conditionsArr = [];
    }

    return (
      <StyledOl>
        {conditionsArr.map((c) => (
          <li className="numbered-list-item" key={c.paragraph}>
            {c.paragraph}
            {!!c.subParagraphs && (
              <ul className="dashed-list" style={{ paddingLeft: '15px' }}>
                {c.subParagraphs.map(sp => (
                    <li key={sp}>{sp}</li>
                ))}
              </ul>
            ) }
          </li>
        ))}
        {fileUrl && <span onClick={openLink}>ПРЕГЛЕД НА ПЪЛНИТЕ УСЛОВИЯ</span>}
      </StyledOl>
    );
  }

  return (
    <StyledRightModal
      open={open}
      handleClose={handleClose}
      title={title}
      Content={() => (
        <>
          {renderContent()}
        </>
      )}
      sx={{
        mx: {
          xs: '3rem',
          lg: '0rem',
        }
      }}
    />
  )
};

OfferConditionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  conditions: PropTypes.array,
};

export default OfferConditionsModal;
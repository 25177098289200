export const colors = {
  primary: "#000000",
  secondary: "#e6401e",
  white: "#ffffff",
  gray10: "#f2f2f2",
}

export const backgroundColors = {
  transparent: "#FFFFFF",
  gray: "#F3F3F3",
  yellow: "#FFF2D9",
  pink: "#FCE8E4",
  green: "rgba(137, 186, 42, .3)",
}

export default colors

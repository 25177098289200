import React from 'react';
import SvgLogoIcon from "../../../images/contract-outline.inline.svg"

const ContractOutlineIcon = ({ width, height = "250" }) => {
  return (
    <>
      <SvgLogoIcon width={width} height={height} />
    </>
  )
}

export default ContractOutlineIcon
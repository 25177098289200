import { Button, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';

const SendEmailForm = ({initialValues, validationSchema, onSubmit, ...props}) => {
  return (
     <div {...props}>
       <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={onSubmit}
       >
         {({
           errors,
           values,
           touched,
           setValues,
           setFieldValue,
           handleChange,
         }) => (
           <Form className="contact-form">
             <div className="col-md-12">
               <div className="row">
                 <div className="col-md-12">
                   <TextField
                     id="email"
                     name="email"
                     label="Email*"
                     value={values.email}
                     onChange={handleChange}
                     error={touched.email && Boolean(errors.email)}
                     helperText={touched.email && errors.email}
                     variant="outlined"
                     style={{ margin: 8 }}
                   />
                 </div>
               </div>
             </div>

             <div className="col-md-12">
               <div className="row">
                 <div className="col-md-12">
                   <TextField
                     id="names"
                     name="names"
                     label="Имена"
                     variant="outlined"
                     fullWidth
                     style={{ margin: 8 }}
                     value={values.names}
                     onChange={handleChange}
                     error={touched.names && Boolean(errors.names)}
                     helperText={touched.names && errors.names}
                   />
                 </div>
               </div>
             </div>

             <div className="col-md-12">
               <div className="row">
                 <div className="col-md-12">
                   <TextField
                     id="phone"
                     name="phone"
                     label="Tелефон"
                     variant="outlined"
                     style={{ margin: 8 }}
                     value={values.phone}
                     onChange={handleChange}
                     error={touched.phone && Boolean(errors.phone)}
                     helperText={touched.phone && errors.phone}
                   />
                 </div>
               </div>
             </div>

             <div className="col-md-12">
               <Button
                 variant="contained"
                 color="secondary"
                 fullWidth
                 type="submit"
               >
                 Изпрати
               </Button>
             </div>
           </Form>
         )}
       </Formik>
     </div>
  )
};

export default SendEmailForm;
import React from "react"
import SvgLogoIcon from "../../../images/info.inline.svg"

const InfoIcon = ({ width = "24", height = "24" }) => {
  return (
    <>
      <SvgLogoIcon width={width} height={height} />
    </>
  )
}

export default InfoIcon

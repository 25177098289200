import React from "react";
import { graphql, Link } from "gatsby";
import { navigate } from "@reach/router";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material";
import Box from "@mui/material/Box";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { getPrice } from "../helpers/calculatePrice";
import {
  MONEY_TO_KW_MULTIPLIER,
  defaultConsumption,
  dayNightProportion,
  defaultInstalledPower,
  defaultМeter,
  defaultLevel,
  vat,
  STOCK_PRICE_POPUP_HEADING,
  STOCK_PRICE_POPUP_BODY,
} from "../helpers/constants";
import roundNumber from "../helpers/roundNumber";
import * as yup from "yup";
import { Typography, useTheme, Button, useMediaQuery } from "@mui/material";
import InfoIcon from "../components/atoms/icons/InfoIcon";
// TODO: svg version is not able to render properly, need clean ver
import Image from "../components/atoms/image/Image";
import ConditionsIcon from "../images/conditions.png";
import ContactIcon from "../images/contract.png";
import DocumentsIcon from "../images/documents.png";
import PriceUpDownIcon from "../images/icon-price-up-down.png";

import MonthlyBill from "../components/organisms/monthly-bill/MonthlyBill";
import OfferCondition from "../components/organisms/offers/OfferCondition";
import AlertModal from "../components/organisms/alert-modal/AlertModal";
import SendEmailIcon from "../components/atoms/icons/SendEmailIcon";
import ContractOutlineIcon from "../components/atoms/icons/ContractOutline";
import OfferConditionsModal from "../components/organisms/offer-conditions-modal/OfferConditionsModal";
import SendEmailForm from "../components/organisms/send-email/SendEmailForm";
import OfferDistributor from "../components/organisms/offers/containers/offer-distributor/OfferDistributor";
import ExclusiveOfferBandSVG from "../images/exclusiveOffer.inline.svg";

import ShowPdfOfferModal from "../components/organisms/show-offer-modal/ShowPdfOfferModal";
import { fireGAEvent } from "../helpers/ga";

const StyledExclusiveOfferBand = styled(ExclusiveOfferBandSVG)`
  position: absolute;
  z-index: 2;
  right: 0;
  top: -9px;
`;

const BoxStyle = styled(Box)`
  position: relative;
  margin-bottom: 2rem;
  box-shadow: 0px 1px 3px #00000066 !important;
  overflow: hidden;
`;

const BoxBackgroundStyle = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;

const HintStyle = styled.div`
  display: flex;
  align-items: start;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  color: #000;
  font-weight: 400;
  border: 1px solid #e0e0e0;

  @media (max-width: 767px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  svg {
    margin-right: 1rem;
  }

  span {
    color: #e6401e;
    font-weight: 500;
  }
`;

const StockPriceContainer = styled.div`
  margin-top: 0.2rem;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: flex-end;
`;

const StockPrice = styled.div`
  display: flex;
  align-items: center;
  min-width: 152px;
  color: #e6401e;
  background-color: rgba(230, 64, 30, 0.2);
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  padding: 0.1rem 0.5rem;

  svg {
    margin-right: 0.2rem;
  }
`;

const StyledOfferDistributorOffer = styled.div`
  @media only screen and (max-width: 525px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 450px) {
    h1 {
      text-align: center;
    }
  }
`;

const OfferImage = styled(Image)`
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 8px;
  margin-bottom: 0;
`;

const DocList = styled.div`
  position: relative;
`;
const DocItem = muiStyled("div")(
  ({ theme, empty }) => `
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  text-decoration: ${empty ? "none" : "underline"};
  text-decoration-color: ${theme.palette.secondary.main};
  text-decoration-skip-ink: none;

  &:hover {
    cursor: pointer;
  }

  & span {
    color: ${empty ? theme.palette.grey[600] : theme.palette.secondary.main};
    font-weight: 500;
  }
`
);

const DocAlertModal = styled(AlertModal)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  margin: 0 -33px -33px;
  width: calc(100% + 66px) !important;
  font-size: 14px;
`;

const StockAlertModal = styled(AlertModal)`
  position: absolute;
  top: 50px;
  left: 200px;
  z-index: 999;
  margin: 0 -33px -33px;
  width: calc(66% + 66px);
  font-size: 14px;
`;

const ContractLink = muiStyled(Link)(
  ({ theme }) => `
  background: ${theme.palette.secondary.main};`
);

const initialValues = {
  names: "",
  email: "",
  phone: "",
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Въведете валиден email адрес.")
    .required("Задължително поле."),
});

function DistibutorOfferPageTemplate(props) {
  const theme = useTheme();
  const isBelowMdBreakpoint = useMediaQuery(theme.breakpoints.down("1024"));

  const layout = props.data.contentfulDistributorOffer;

  const {
    offerName,
    distributor,
    details,
    greenEnergy,
    specialOffer,
    offerConditions,
    metaDescription,
    openGraphImage,
  } = layout;

  const meta = [
    { name: "description", content: metaDescription?.metaDescription },
    { property: "og:description", content: metaDescription?.metaDescription },
    { property: "og:image", content: openGraphImage?.url },
  ];
  
  const seoTitle = offerName + " | " + distributor.title;
  const params = new URLSearchParams(props.location.search);
  const isPibex = details?.market && details.market === "pibex" ? true : false;
  const isSpecialOffer = Boolean(specialOffer);
  const [docIsOpen, setDocIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showExample, setShowExample] = React.useState(false);
  const [stockPopupIsOpen, setStockPopupIsOpen] = React.useState(false);

  // Parse query parameters
  const initialMeter = params.get("meter");

  const initialDayConsumption = params.get("day");
  const initialNightConsumption = params.get("night");
  const initialLevel = params.get("level");
  const initialInstalledPower = params.get("instPower");
  const initialConsumptionLv = params.get("consumptionlv");
  const initialOperator = params.get("operator") || "cez";

  var initialConsumption = params.get("consumption");
  if (initialDayConsumption && initialNightConsumption)
    initialConsumption =
      Number(initialDayConsumption) + Number(initialNightConsumption);
  else if (initialConsumptionLv)
    initialConsumption = roundNumber(
      initialConsumptionLv * MONEY_TO_KW_MULTIPLIER
    );
  else if (!initialConsumption) initialConsumption = defaultConsumption;

  // Set initial data for precalculation and populating form
  const initialData = {
    consumption: roundNumber(initialConsumption),
    meter: initialMeter || defaultМeter,
    level: initialLevel || defaultLevel,
    day:
      initialDayConsumption ||
      Math.round(initialConsumption * (1 - dayNightProportion)),
    night:
      initialNightConsumption ||
      Math.round(initialConsumption * dayNightProportion),
    instPower: initialInstalledPower || defaultInstalledPower,
    operator: initialOperator,
  };
  let priceComposite = getPrice(
    initialData.consumption,
    initialData.meter,
    initialData.day,
    initialData.night,
    initialData.instPower,
    JSON.stringify(details),
    initialData.level === "m",
    initialData.operator
  );

  const totalPrice =
    priceComposite.price +
    priceComposite.duty +
    priceComposite.ZO +
    priceComposite.network;
  const offerTotal = totalPrice + totalPrice * vat;

  const message =
    "При консумация " +
    (initialData.meter === "m"
      ? "през деня и нощта съответно " +
        initialData.day +
        " и " +
        initialData.night +
        " кВтч, двутарифен електромер "
      : "на <span>" +
        initialData.consumption +
        " кВтч</span>, еднотарифен електромер, ") +
    "присъединяване към мрежата на " +
    (initialData.level === "m" ? "средно напрежение " : "ниско напрежение ") +
    " и инсталирана мощност " +
    initialData.instPower +
    " кВт";

  const initialValues = {
    names: "",
    email: "",
    phone: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Въведете валиден email адрес.")
      .required("Задължително поле."),
  });

  function onSubmit(fields) {
    // display form field values on success
    const toSend = {
      form: fields,
      page: window.location.href,
      price: priceComposite,
      totalPrice: totalPrice,
    };
    const requestBody = JSON.stringify(toSend, null, 4);
    fetch("https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/contract", {
      method: "POST",
      body: requestBody,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("Success:", requestBody);
        window.location.href = "/blagodarya-suobshtenie";
      })
      .catch((error) => {
        console.error("Error:", error);
        window.location.href = "/blagodarya-suobshtenie?error=" + error;
      });
  }

  const handleAlertModalVisibility = (open) => {
    setDocIsOpen(open);
  };

  const handleStockModalVisibility = (open) => {
    setStockPopupIsOpen(open);
  };

  return (
    <Layout>
      <Seo meta={meta} title={seoTitle} description={metaDescription?.metaDescription} />
      <section className="background-gray section--spacing">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* Бутон назад */}
              <div className="col-md-12">
                <a
                  href={"/electricity-meter/" + props.location.search}
                  className="button--back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ position: "absolute" }}>
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="artboard"
                          width="24"
                          height="24"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g id="icon-chevron-left" clipPath="url(#clip-path)">
                      <path
                        id="chevron-left"
                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0m0,22.107h0A10.107,10.107,0,1,1,22.107,12,10.118,10.118,0,0,1,12,22.107M10.882,12l4.047,4.041-1.341,1.342L8.205,12l5.383-5.382,1.339,1.339Z"
                        transform="translate(0 0)"
                      />
                    </g>
                  </svg>
                  <span style={{ marginLeft: "34px" }}>Всички оферти</span>
                </a>
              </div>
              {/* Заглавие */}
              <div className="row">
                <div className="col-md-12">
                  <StyledOfferDistributorOffer
                    className="d-flex align-items-end justify-content-between"
                    style={{ marginBottom: "3rem" }}>
                    <h1 className="heading-1" style={{ marginBottom: 0 }}>
                      "{offerName}"
                    </h1>
                    <OfferDistributor offer={layout} alignItems="end" />
                  </StyledOfferDistributorOffer>
                </div>
              </div>
              {/* Месечна оценка */}
              <div className="row mb-020">
                <div className="col-md-7">
                  <BoxStyle
                    sx={{
                      boxShadow: 1,
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      py: 4,
                      px: 4,
                      "@media (max-width:767px)": {
                        px: 2,
                      },
                    }}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-end justify-content-between">
                          <h3 className="heading-3" style={{ marginBottom: 0 }}>
                            Месечна оценка
                          </h3>
                          <div
                            className={`${
                              isPibex && "highlighted"
                            } d-flex align-items-end`}>
                            <div
                              className="heading-2"
                              style={{
                                marginBottom: 0,
                                marginRight: "0.5rem",
                                lineHeight: 1.1,
                              }}>
                              {roundNumber(offerTotal)}
                            </div>{" "}
                            лв./ мес.
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ marginBottom: "3rem" }}>
                        {isPibex && (
                          <>
                            <StockPriceContainer
                              onClick={() => setStockPopupIsOpen(true)}>
                              <StockPrice>
                                <Image src={PriceUpDownIcon} width={20} />
                                Борсова цена
                              </StockPrice>
                            </StockPriceContainer>
                            <StockAlertModal
                              heading={STOCK_PRICE_POPUP_HEADING}
                              content={STOCK_PRICE_POPUP_BODY}
                              isOpen={stockPopupIsOpen}
                              onAlertModal={handleStockModalVisibility}
                              arrow={false}
                              icon={null}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <HintStyle>
                      <div>
                        <InfoIcon />
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </HintStyle>
                    <MonthlyBill
                      greenEnergy={greenEnergy}
                      priceComposite={priceComposite}
                      totalPrice={totalPrice}
                      isPibex={isPibex}
                    />
                    <div className="d-flex align-items-end justify-content-end">
                      Общо:{" "}
                      <span
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          lineHeight: 1.2,
                          padding: " 0 .5rem",
                        }}>
                        {roundNumber(offerTotal)}
                      </span>{" "}
                      лв.
                    </div>
                  </BoxStyle>
                </div>
                <div className="col-md-5">
                  {isSpecialOffer && <StyledExclusiveOfferBand />}
                  <BoxStyle
                    sx={{
                      borderTop: 5,
                      borderColor: "#B5B5B5",
                      boxShadow: 1,
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      py: 4,
                      px: 4,
                    }}>
                    <h3
                      className="heading-3"
                      style={{ marginBottom: "2rem", paddingRight: "1rem" }}>
                      Условия на офертата
                    </h3>

                    <OfferCondition conditions={offerConditions} />
                  </BoxStyle>

                  <BoxStyle
                    sx={{
                      borderTop: 5,
                      borderColor: "#B5B5B5",
                      boxShadow: 1,
                      borderRadius: 1,
                      bgcolor: "background.paper",
                      py: 4,
                      px: 4,
                    }}
                    style={{ overflow: "inherit" }}>
                    <h3 className="heading-3" style={{ marginBottom: "2rem" }}>
                      {layout.contract ? "Документи" : "Персонализиран договор"}
                    </h3>

                    {layout.contract ? (
                      <>
                        <DocList>
                          <DocItem onClick={() => setShowExample(true)}>
                            <div>
                              <OfferImage src={ContactIcon} />
                            </div>
                            <span>Образец на Договор</span>
                          </DocItem>
                          <DocItem onClick={() => setOpen(true)}>
                            <div>
                              <OfferImage src={ConditionsIcon} />
                            </div>
                            <span>Акценти в Договора</span>
                          </DocItem>

                          <OfferConditionsModal
                            open={open}
                            handleClose={() => setOpen(false)}
                            title="Условия по договор"
                            fileUrl={layout.conditionsFile?.url}
                            conditions={layout.conditions?.longConditions}
                          />

                          <ShowPdfOfferModal
                            open={showExample}
                            closeModal={() => setShowExample(false)}
                            maxWidth="70rem"
                            contract={layout.contract}
                            documentProps={{
                              loading: "Зареждане на договор...",
                              noData: "Няма качен договор",
                              error: "Получи се грешка",
                            }}
                            pageRenderOptions={{
                              className: "d-flex justify-content-center",
                              scale: "1.8",
                            }}
                          />
                          {/* <ShowOfferModal
                          open={showExample}
                          closeModal={() => setShowExample(false)}
                          maxWidth="70rem"
                          offerPreviewProps={{
                            showFullOffer: true,
                            fileName: layout.contract?.file?.fileName,
                            logo: layout?.distributor?.fullLogo ? {
                              src: layout.distributor.fullLogo.file.url,
                              alt: layout.distributor.fullLogo.title,
                            } : undefined,
                            data: {},
                            wrapperStyle:{
                              flexWrap: 'nowrap',
                              height: '100%',
                            },
                            watermark: true,
                          }}
                        /> */}
                        </DocList>
                      </>
                    ) : (
                      <>
                        <DocList>
                          {/* // #tuka */}
                          <DocItem
                            onClick={() =>
                              layout?.contract
                                ? false
                                : window.scrollTo({
                                    top: document?.body?.scrollHeight * 0.475,
                                  })
                            }
                            empty>
                            <div>
                              <OfferImage src={ContactIcon} />
                            </div>
                            <p style={{ maxWidth: "85%" }}>
                              Условията по този договор се персонализират за
                              всеки клиент. Попълнете формата ни за запитвания,
                              за да ви изпратим възможно най-добрите условия.
                            </p>
                          </DocItem>
                          {/* <DocItem onClick={() => setDocIsOpen(true)} empty>
                            <div>
                              <OfferImage src={ConditionsIcon} />
                            </div>
                            <span>Условия</span>
                          </DocItem> */}

                          {/* <DocAlertModal
                            heading="Очаквайте съвсем скоро"
                            content="<ul><li>да можете да преглеждате всички документи за офертата</li><li>да сключвате договор с избрания търговец директно през нашата платформа.</li></ul>"
                            isOpen={docIsOpen}
                            onAlertModal={handleAlertModalVisibility}
                            arrow={false}
                            icon={DocumentsIcon}
                            className="doc-alert"
                          /> */}
                        </DocList>
                      </>
                    )}
                  </BoxStyle>
                </div>
              </div>

              {layout.contract ? (
                <BoxStyle
                  sx={{
                    boxShadow: 1,
                    borderRadius: 1,
                    bgcolor: "#f3f3f3",
                    py: 4,
                    px: 4,
                  }}>
                  <BoxBackgroundStyle>
                    {
                      <ContractOutlineIcon
                        width={!isBelowMdBreakpoint ? "190" : ""}
                      />
                    }
                  </BoxBackgroundStyle>
                  <Box className="row" sx={{ alignItems: "center" }}>
                    <div className="col-md-5">
                      <Typography
                        variant="h4"
                        sx={{
                          pb: "15px",
                          fontSize: "22px",
                          fontWeight: 600,
                          textAlign: { xs: "center", md: "start" },
                        }}>
                        ОФЕРТАТА ВИ ХАРЕСВА?
                      </Typography>

                      <Typography variant="body1" style={{ fontSize: "20px" }}>
                        Заявете договор с избрания доставчик, като попълните
                        данни за вашия обект и контакти в две стъпки.
                      </Typography>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      <Button
                        color="secondary"
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                        }}
                        sx={{
                          mt: isBelowMdBreakpoint && "4rem",
                        }}
                        type="button"
                        fullWidth
                        LinkComponent={ContractLink}
                        to={`${props.path}/signOffer${
                          props.location.search ? props.location.search : ""
                        }`}
                        onClick={() =>
                          fireGAEvent("sign_contract_btn_clicked", {
                            price: roundNumber(offerTotal),
                            offerName: offerName,
                            partner: layout?.distributor.title,
                          })
                        }>
                        Заяви договор
                      </Button>
                    </div>
                  </Box>
                </BoxStyle>
              ) : (
                <BoxStyle
                  sx={{
                    boxShadow: 1,
                    borderRadius: 1,
                    bgcolor: "#f3f3f3",
                    py: 4,
                    px: 4,
                  }}>
                  <BoxBackgroundStyle>{<SendEmailIcon />}</BoxBackgroundStyle>
                  <div className="row">
                    <div className="col-md-5">
                      <h4>ИМАТЕ НУЖДА ОТ СЪДЕЙСТВИЕ ЗА СКЛЮЧВАНЕ НА ДОГОВОР?</h4>

                      <h4>НЕОБХОДИМА ВИ Е ДОПЪЛНИТЕЛНА КОНСУЛТАЦИЯ ИЛИ СЪВЕТ?</h4>

                      <p>
                        Оставете данните си, а ние ще се свържем с вас, за да Ви
                        помогнем!
                      </p>
                    </div>
                    <div className="col-md-2"></div>
                    <SendEmailForm
                      className="col-md-5"
                      id="offer_request"
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    />
                  </div>
                </BoxStyle>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default DistibutorOfferPageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulDistributorOffer(id: { eq: $id }, node_locale: { eq: "bg" }) {
      offerName
      conditions {
        longConditions {
          paragraph
          subParagraphs
        }
      }
      conditionsFile {
        url
      }
      contract {
        internal {
          content
          contentDigest
          description
          mediaType
          contentFilePath
        }
        publicUrl
        file {
          url
          fileName
        }
        url
      }
      offerConditions {
        ... on ContentfulDistributorOfferCondition {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      details {
        type
        priceWinter
        priceSummer
        priceNight
        priceDay
        price
        operator
        fee
        market
        marginMinBGN
        margin
        marginMin
        marginPercent
        prices {
          min
          max
          priceWinter
          priceSummer
          priceNight
          priceDay
          price
          fee
          market
          marginMinBGN
          margin
          marginMin
          marginPercent
        }
      }
      greenEnergy
      specialOffer
      description {
        raw
      }
      distributor {
        title
        website
        logo {
          gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
        }
        fullLogo {
          title
          file {
            url
          }
        }
        contractSigned
      }
      metaDescription {
        metaDescription
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      openGraphImage {
        url
      }
    }
  }
`;

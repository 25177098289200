import React from "react"
import SvgLogoIcon from "../../../images/send-email.inline.svg"

const SendEmailIcon = ({ width = "352", height = "298" }) => {
  return (
    <>
      <SvgLogoIcon width={width} height={height} />
    </>
  )
}

export default SendEmailIcon
